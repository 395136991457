<template>
  <section>
    <div class="w-full py-16 px-4">
      <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
        <h1 class="font-bold mb-2 border-b" v-text="$t('meta.careers.title')" />
        <h2 class="text-2xl font-light text-gray-700 max-w-3xla">
          {{ $t("careers.headingText") }}
        </h2>
      </div>
    </div>
    <div class="bg-white py-16 px-4">
      <div class="w-full mx-auto xl:max-w-screen-lg xxl:max-w-screen-xl">
        <h3 class="font-bold text-center">{{ $t("careers.openPositions") }}</h3>
        <p class="text-xl font-light text-gray-700 text-center max-w-xl mx-auto mb-4">
          {{ $t("careers.openPositionsText") }}
        </p>
        <div class="space-y-8 mt-8">
          <details class="group">
            <summary class="cursor-pointer">
              <span class="font-bold text-xl">Produktion - Operatör</span>
              <div class="group-open:hidden open:hidden text-sm text-gray-500">
                Klicka för att läsa mer
              </div>
            </summary>
            <div class="mt-3 text-gray-600 max-w-4xl">
              <p class="mb-4">
                Add North 3D AB är ett svenskt innovationsföretag i framkant av den snabbt växande
                3D-printingbranschen. Vi utvecklar och tillverkar högkvalitativa filament och
                materiallösningar för industri, produktutveckling och kreativa användare världen
                över. Med en stark passion för hållbarhet och teknisk utveckling strävar vi efter
                att leverera pålitliga och innovativa produkter som tar additiv tillverkning till
                nästa nivå.
              </p>
              <p class="mb-4">
                Som Produktionsoperatör hos oss blir du en viktig del av vår tillverkningsprocess.
                Du arbetar med extrudering och kvalitetssäkring av 3D-filament, hanterar maskiner
                och ser till att produktionen flyter på enligt plan. Vi erbjuder en varierad och
                utvecklande arbetsmiljö där du får möjlighet att arbeta med modern teknik och
                hållbara material.
              </p>
              <p class="font-bold">Dina huvudsakliga arbetsuppgifter:</p>
              <ul class="list-disc list-inside mb-4">
                <li>Övervaka och köra extruderingsmaskiner för tillverkning av 3D-filament</li>
                <li>
                  Kontrollera produktens kvalitet och mått för att säkerställa att den uppfyller
                  våra höga standarder
                </li>
                <li>Hantera råmaterial och färgtillsatser i produktionen</li>
                <li>Utföra underhåll och felsökning på maskiner vid behov</li>
                <li>
                  Följa säkerhetsföreskrifter och arbeta strukturerat enligt produktionsplanen
                </li>
                <li>
                  Samarbeta med teamet och bidra till effektivisering och förbättring av processer
                </li>
              </ul>
              <p class="font-bold">Vem är du?</p>
              <ul class="list-disc list-inside mb-4">
                <li>
                  Erfarenhet från produktion eller industri, gärna inom plast- eller
                  kemitillverkning
                </li>
                <li>Intresse för teknik, maskiner och kvalitetskontroll</li>
                <li>God noggrannhet och ansvarskänsla</li>
                <li>Förmåga att arbeta både självständigt och i team</li>
                <li>Grundläggande kunskaper i svenska och engelska</li>
                <li>Truckkort och erfarenhet av materialhantering (meriterande, men inget krav)</li>
              </ul>
              <p class="font-bold">Vi erbjuder</p>
              <ul class="list-disc list-inside mb-4">
                <li>Ett stabilt och växande företag i en framtidsbransch</li>
                <li>Arbete med modern teknik och högkvalitativa material</li>
                <li>
                  Möjlighet till utbildning och utveckling inom produktion och maskinhantering
                </li>
                <li>En positiv och samarbetsinriktad arbetsmiljö</li>
                <li>Möjlighet att påverka och bidra till förbättringar i produktionen</li>
              </ul>
              <p>Låter det här som något för dig? Skicka in din ansökan idag!</p>
            </div>
          </details>

          <details class="group">
            <summary class="cursor-pointer">
              <span class="font-bold text-xl">Produktion - Platschef</span>
              <div class="group-open:hidden open:hidden text-sm text-gray-500">
                Klicka för att läsa mer
              </div>
            </summary>
            <div class="mt-3 text-gray-600 max-w-4xl">
              <p class="mb-4">
                Add North 3D AB är ett svenskt innovationsföretag i framkant av den snabbt växande
                3D-printingbranschen. Vi utvecklar och tillverkar högkvalitativa filament och
                materiallösningar för industri, produktutveckling och kreativa användare världen
                över. Med en stark passion för hållbarhet och teknisk utveckling strävar vi efter
                att leverera pålitliga och innovativa produkter som tar additiv tillverkning till
                nästa nivå.
              </p>
              <p class="mb-4">
                Som platschef inom produktion ansvarar du för att säkerställa en effektiv och
                kvalitativ tillverkning av våra 3D-filament. Du leder och coachar
                produktionspersonalen, optimerar arbetsflöden och ser till att produktionsmålen
                uppfylls.
              </p>
              <p class="font-bold">Dina huvudsakliga arbetsuppgifter:</p>
              <ul class="list-disc list-inside mb-4">
                <li>
                  Övergripande ansvar för produktion, drift och personal på vår
                  produktionsanläggning
                </li>
                <li>
                  Säkerställa att produktionen håller hög kvalitet, effektivitet och
                  leveranssäkerhet
                </li>
                <li>Leda och utveckla teamet för att skapa en positiv och engagerad arbetsmiljö</li>
                <li>Ansvara för arbetsmiljö, säkerhet och efterlevnad av rutiner och regelverk</li>
                <li>
                  Samarbeta med andra avdelningar, såsom R&D, inköp och försäljning, för att
                  säkerställa smidiga produktionsprocesser
                </li>
                <li>
                  Driva förbättringsarbete och optimering av produktionsflöden för ökad
                  produktivitet
                </li>
              </ul>
              <p class="font-bold">Vem är du?</p>
              <ul class="list-disc list-inside mb-4">
                <li>
                  Erfarenhet av produktionsledning, gärna inom plast-, kemi- eller
                  tillverkningsindustrin
                </li>
                <li>Ledarskapsförmåga och erfarenhet av att motivera och utveckla team</li>
                <li>Förståelse för lean production och produktionsoptimering</li>
                <li>
                  Teknisk kompetens, gärna erfarenhet av maskiner och processer inom
                  plasttillverkning
                </li>
                <li>Strukturerad och lösningsorienterad approach till produktion och drift</li>
                <li>Goda kunskaper i svenska och engelska, både i tal och skrift</li>
              </ul>
              <p class="font-bold">Vi erbjuder</p>
              <ul class="list-disc list-inside mb-4">
                <li>En spännande och varierad roll i en framtidsbransch</li>
                <li>Möjlighet att arbeta med hållbara och innovativa material inom 3D-printing</li>
                <li>En dynamisk och utvecklande arbetsmiljö med stora möjligheter att påverka</li>
                <li>Stora möjligheter att utvecklas och växa inom bolaget</li>
              </ul>

              <p>Är du den vi söker? Skicka in din ansökan redan idag!</p>
            </div>
          </details>
        </div>
      </div>
    </div>
    <div class="w-full pt-16 pb-20 px-4">
      <div class="mx-auto max-w-md">
        <h4 class="font-bold text-4xl">{{ $t("careers.apply") }}</h4>
        <p class="mb-4">{{ $t("careers.form") }}</p>
        <form id="careers" ref="careers" @submit.prevent="send">
          <div class="shadow-lg bg-white rounded-xl">
            <div class="flex">
              <div class="styled-form w-1/2">
                <input
                  name="firstname"
                  class="w-full rounded-tl-xl"
                  type="text"
                  placeholder=" "
                  autocomplete="given-name"
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.firstName')} *`" />
              </div>
              <div class="styled-form w-1/2">
                <input
                  name="lastname"
                  autocomplete="family-name"
                  class="w-full rounded-tr-xl"
                  type="text"
                  placeholder=" "
                  required
                />
                <label class="floating-label" v-text="`${$t('forms.billing.lastName')} *`" />
              </div>
            </div>
            <div class="styled-form w-full">
              <input
                name="email"
                autocomplete="email"
                class="w-full"
                type="email"
                placeholder=" "
                required
              />
              <label class="floating-label" v-text="`${$t('common.email')} *`" />
            </div>
            <div class="styled-form">
              <input
                class="w-full"
                autocomplete="tel"
                name="mobilephone"
                type="tel"
                placeholder=" "
                required
              />
              <label class="floating-label" v-text="`${$t('forms.billing.cellNumber')} *`" />
            </div>
            <div class="styled-form border-l border-r w-full">
              <label
                class="floating-label"
                style="position: relative !important"
                v-text="$t('careers.documents')"
              />
              <input
                class="pt-0 border-none shrink-0 block"
                name="attachments"
                type="file"
                multiple
              />
            </div>

            <div class="styled-form w-full">
              <textarea
                name="msg"
                class="w-full rounded-bl-xl rounded-br-xl"
                placeholder=" "
                rows="4"
                style="margin-bottom: -6px"
                required
              />
              <label class="floating-label" v-text="`${$t('forms.message')} *`" />
            </div>
          </div>
          <button
            class="uppercase main-button w-full mt-8"
            type="submit"
            v-text="$t('forms.send')"
            :disabled="loading"
          />
          <input type="hidden" name="form_type" value="careers" />
        </form>
        <div v-if="sent" class="text-green-300 my-3" v-text="$t('contact.sent')" />
        <div v-if="error" class="text-red-300 my-3" v-text="$t('errors.contactError')" />
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  name: "BecomeReseller",
  data() {
    return {
      loading: false,
      sent: false,
      error: false,
    };
  },
  methods: {
    async send() {
      try {
        this.sent = false;
        this.error = null;
        this.loading = true;
        await axios.post(
          "https://forms.hubspot.com/uploads/form/v2/7263663/e8d3e743-ade0-44bf-9f7f-5be5cd694e56",
          new FormData(this.$refs.careers)
        );
        this.sent = true;
        this.$refs.careers.reset();
      } catch (error) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
