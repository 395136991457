<template>
  <div v-if="fetched" class="flex flex-col items-end">
    <div
      class="h-6 flex items-center"
      :class="{
        'h-6 text-sm': !small,
        'h-4 text-xs': small,
      }"
    >
      <div
        class="rounded-full mr-2"
        :class="{
          'w-3 h-3': !small,
          'w-2 h-2': small,
          [color]: true,
        }"
      />
      <div v-text="text" />
    </div>
    <div class="ml-4" v-if="showDetails && items.length > 1">
      <div v-for="detail in items" :key="detail.name" class="h-4 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="fill-current w-4 h-4 mr-1 text-green-400"
          v-if="detail.totalAvailable >= qtyIncCart * detail.quantity"
        >
          <path d="M18.9,8.1L9,18L4.05,13.05L4.76,12.34L9,16.59L18.19,7.39L18.9,8.1Z" />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="fill-current w-4 h-4 mr-1 text-red-600"
        >
          <path
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
          />
        </svg>
        <div class="text-xs" v-text="detail.name" />
      </div>
    </div>
    <div
      v-if="backInStock && totalAvailable < 14"
      :class="{
        'text-sm': !small,
        'text-xs': small,
      }"
      v-text="backInStock"
    />
    <NotifyInStock
      v-if="showNotify && totalAvailable <= 0"
      :listingId="variation.id"
      class="mt-4"
    />
  </div>
</template>
<script>
import NotifyInStock from "./NotifyInStock.vue";
export default {
  name: "Inventory",
  components: {
    NotifyInStock,
  },
  props: {
    variation: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    qtyIncCart: {
      type: Number,
      required: true,
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
    showNotify: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availability() {
      return this?.variation?.availability || null;
    },
    fetched() {
      return this?.availability?.fetched || false;
    },
    canBeOrdered() {
      return this?.availability?.canBeOrdered || false;
    },
    isBackOrder() {
      return this?.availability?.isBackOrder || false;
    },
    totalAvailable() {
      return this?.availability?.totalAvailable || 0;
    },
    deliveryDate() {
      return this?.availability?.deliveryDate || null;
    },
    items() {
      return this?.availability?.items || [];
    },
    discontinued() {
      return this?.variation?.discontinued || false;
    },
    backInStock() {
      return this?.availability?.deliveryDate && !this.variation.discontinued
        ? this.$t("store.shipping.backInStock", {
            backInStock: this.availability.deliveryDate,
          })
        : "";
    },
    color() {
      if (!this.canBeOrdered) return "bg-red-600";
      if (this.canBeOrdered && this.isBackOrder) return "bg-yellow-600";
      if (this.canBeOrdered && this.totalAvailable >= 14) return "bg-green-400";
      if (this.canBeOrdered && this.totalAvailable < 14) return "bg-yellow-300";
      return "";
    },
    text() {
      if (this.canBeOrdered) {
        // if (this.isBackOrder) {
        //   return this.$t("store.shipping.estimatedDelivery", { deliveryDate: this.deliveryDate });
        // }
        if (this.totalAvailable <= 0) return this.$t("common.outStock");
        if (this.totalAvailable >= 14) return this.$t("common.inStock");
        return this.$t("common.lowStock");
      } else {
        if (this.discontinued) return this.$t("product.discontinued");
        if (this.qtyIncCart === 1) return this.$t("common.outStock");
        return this.$t("common.notEnoughStock");
      }
      return "";
    },
  },
};
</script>
